var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"App_Layout",class:{
      Main_isMobile: _vm.isMobile,
      Main_isDesktop: !_vm.isMobile
    }},[_c('BaseTopMenu',{attrs:{"user":_vm.user}}),_c('router-view'),_c('div',{staticClass:"App_SnackLayout",class:{
        App_SnackActive: _vm.snackActive,
        App_SnacKError: _vm.snackError,
        App_SnacKCorrect: _vm.snackCorrect,
        App_SnacKBlue: _vm.snackBlue,
        App_RealActive: _vm.realActive,
        App_PreActive: _vm.preActive,
        App_AnimIn: _vm.animIntro,
        App_AnimOut: _vm.animOut,
        App_Shake: _vm.snackShake
      }},[_c('div',{staticClass:"App_Snack",on:{"click":function($event){_vm.snackActive = false}}},[_vm._v(_vm._s(_vm.snackText))])]),_vm._m(0),_c('BaseDialog',{attrs:{"active":_vm.loginDialog,"transparent":false,"lazy":true,"max-width":"420px","min-width":"240px","zindex":"102"},on:{"close":function($event){_vm.loginDialog = false;}}},[_c('div',{staticStyle:{}},[_c('MainLogin',{attrs:{"wrap":true},on:{"success":function($event){_vm.loginDialog = false;},"close":function($event){_vm.loginDialog = false;}}})],1)]),_c('BaseDialog',{attrs:{"active":_vm.brakeDialog,"transparent":false,"lazy":true,"max-width":"250px","min-width":"240px","zindex":"102"},on:{"close":function($event){_vm.brakeDialog = false;}}},[_c('div',{staticStyle:{}},[_c('div',{staticClass:"App_BrakeItem"},[_c('div',{staticClass:"App_BrakeLeft"},[_c('span',{staticClass:"Row_DialogCardStatCorrect"},[_vm._v("A")]),_vm._v(" class: ")]),_c('div',{staticClass:"App_BrakeRight"},[_c('div',[_vm._v("★★★")]),_c('div',[_vm._v("100-0 in ~4.0s")])])]),_c('div',{staticClass:"App_BrakeItem"},[_c('div',{staticClass:"App_BrakeLeft"},[_vm._v("B class: ")]),_c('div',{staticClass:"App_BrakeRight"},[_c('div',[_vm._v("★★☆")]),_c('div',[_vm._v("100-0 in ~4.9s")])])]),_c('div',{staticClass:"App_BrakeItem"},[_c('div',{staticClass:"App_BrakeLeft"},[_c('span',{staticClass:"Row_DialogCardStatRed"},[_vm._v("C")]),_vm._v(" class: ")]),_c('div',{staticClass:"App_BrakeRight"},[_c('div',[_vm._v("★☆☆")]),_c('div',[_vm._v("100-0 in ~5.7s")])])])])]),_c('portal-target',{attrs:{"name":"app_dialogs","multiple":""}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"App_PrintContainer"}},[_c('canvas',{staticStyle:{"border":"1px solid #000000"},attrs:{"id":"printCanvas","width":"200","height":"100"}})])
}]

export { render, staticRenderFns }