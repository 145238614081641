export default {
  "language": "RU",
  "m_campaign": "Кампания",
  "m_home": "Главная",
  "m_compare": "Сравнить",
  "m_challenges": "Испытания",
  "m_event": "Событие",
  "m_events": "События",
  "m_options": "Настройки",
  "m_charts": "Графики",
  "m_about": "О нас",
  "m_vChanges": "Изменения {version}",
  "m_library": "Библиотека",
  "m_addLibrary": "Добавить в библиотеку",
  "m_copy": "Копировать",
  "m_sharableLink": "Общедоступная ссылка",
  "m_listCars": "Список авто",
  "m_type": "Тип",
  "m_search": "Поиск",
  "m_searchCar": "Искать авто",
  "m_searchTracks": "Искать трассы",
  "m_selectTrack": "Выбрать трассу",
  "m_contributors": "Авторы",
  "m_printBy": "напечатано",
  "m_display": "Показать",
  "m_colors": "Цвета",
  "m_more3dot": "Ещё...",
  "m_trackset": "Набор трасс",
  "m_clear": "Очистить",
  "m_clearCars": "Очистить авто",
  "m_load": "Загрузить",
  "m_loadChart": "Загрузить график",
  "m_memory": "Слот памяти",
  "m_image": "Изображение",
  "m_pleaseWait3dot": "Пожалуйста подождите...",
  "m_downloadPng": "Скачать PNG",
  "m_save": "Сохранить",
  "m_cancel": "Отменить",
  "m_login": "Войти",
  "m_gotoLogin": "Вернуться ко входу",
  "m_email": "Почта",
  "m_emailVerification": "Подтверждение почты",
  "m_username": "Имя пользователя",
  "m_password": "Пароль",
  "m_newPassword": "Новый пароль",
  "m_repeatPassword": "Повторите пароль",
  "m_repeat": "Повторить",
  "m_charLeast": "минимум {n} символов",
  "m_register": "Регистрация",
  "m_forgot": "Забыли",
  "m_allmostDone": "Почти готово",
  "m_here": "Здесь",
  "m_filter": "Фильтр | Фильтры",
  "m_change": "Изменить",
  "m_analyze": "Анализировать",
  "m_submitReview": "Отправить на проверку",
  "m_saveToLibrary": "Сохранить в библиотеку",
  "m_delete": "Удалить",
  "m_approve": "Одобрить",
  "m_round": "Раунд | Раунды",
  "m_requirements": "Требования",
  "m_empty": "Пустой",
  "m_emptyRound": "Пустой раунд",
  "m_unlockRound": "Разблокировать этот раунд",
  "m_lockRound": "Заблокировать этот раунд для меня",
  "m_findBest": "Найти лучших",
  "m_other": "Другое",
  "m_win": "успех",
  "m_lose": "провал",
  "m_draw": "ничья",
  "m_notime": "нет данных",
  "m_select": "выбрать",
  "m_resetSolutions": "Сбросить только решения",
  "m_resetRound": "Сбросить раунд",
  "m_includeDownvote": "Включать результаты с негативными отзывами?",
  "m_showDataFromPast": "Показать данные из прошлых версий",
  "m_showOldTags": "Показать устаревшие метки",
  "m_challengeName": "Название испытания",
  "m_eventName": "Название события",
  "m_numberRounds": "Количество раундов",
  "m_yes": "Да",
  "m_no": "Нет",
  "m_new": "Новый",
  "m_templateGuide": "Руководство по шаблонам",
  "m_createNewCg": "Создать новое испытание",
  "m_createNewEvent": "Создать новое событие",
  "m_title": "Название",
  "m_twisty": "Извилистые",
  "m_drag": "Трассы",
  "m_city": "Улицы",
  "m_rqLimit": "Лимит RQ",
  "m_points": "Очки",
  "m_time": "Время",
  "m_uploadPrint": "Загрузить снимок",
  "m_done": "Готово",
  "m_sort": "Сортировать",
  "m_send": "Отправить",
  "m_backHome": "Назад на главную",
  "m_any": "Любой",
  "m_rarity": "Редкость",
  "m_car": "Авто | Авто",
  "m_changed": "Изменено",
  "m_noRecords": "Нет записей",
  "m_showOtherTracks": "Показать другие трассы",
  "m_showMore": "Показать больше",
  "m_lastContribution": "Последние авторы",
  "m_nothingFound": "Ничего не найдено",
  "m_changedCars": "изменённые авто",
  "m_uploadSuccess": "Загружено успешно",
  "m_noData": "Нет данных для отображения",
  "m_noTimesFound": "Результаты не найдены",
  "m_logoutSuccess": "Выход успешен",
  "m_loginSuccess": "Вы вошли",
  "m_deleteSuccess": "Успешно удалено",
  "m_saveSuccess": "Успешно сохранено",
  "m_approveSuccess": "Успешно одобрено",
  "m_sentReview": "Отправлено на проверку",
  "m_language": "Язык",
  "p_userSentCgForAnalyse": "Спасибо! Ваш раунд будет проанализирован. Присоединяйтесь к нашему Discord серверу!",
  "p_emptyRoundForUser": "Этот раунд ещё не готов. Вы можете нам помочь создать его и отправить на проверку.",
  "p_emptyRound2": "Этот раунд ещё не готов. Если Вы сейчас на этом раунде, Вы можете нам помочь включить его.",
  "p_emptyRoundLogin": "Войдите, чтобы начать создавать этот раунд, или свяжитесь с модераторами на нашем Discord сервере:",
  "p_lockRoundPhrase": "Заблокировать этот раунд, чтобы Вы начали делать его",
  "p_modDoingRound": "{mod} делает этот раунд",
  "p_patronsOnly": "Эта функция доступна только для патреонов (Уровень {tier}).",
  "p_bestOfDescription": "Возвращает список лучших авто для данной трассы и фильтра. Например:",
  "p_chartsDescription": "Добавляет все результаты для трассы на график. Для демонстрации:",
  "p_templateWarning": "Убедитесь что Ваш шаблон может быть полезным для сообщества, иначе он не будет одобрен.",
  "p_about1": "Этот проект сделан TiagoXavi и он не связан с Hutch Games Ltd.",
  "p_about2": "Столкнулись с проблемой или есть предложение? Присоединяйтесь к Discord серверу или отправьте письмо (mighty.boy@topdrivesrecords.com)",
  "p_about3": "TDR абсолютно бесплатный. Вы можете поддержать проект чтобы он продолжал быть онлайн и получать новые улучшения.",
  "p_resetPassHelper": "Введите свою почту для получения ссылки на сброс пароля.",
  "p_resetPassIfExist": "Если пользователь с такой почтой существует, ссылка на сброс пароля была отправлена.",
  "p_confirmationWillSent": "Будет отправлено письмо для подтверждения",
  "p_checkYourEmailBox": "Пожалуйста, проверьте свою почту",
  "p_spamToo": "Папку спама тоже!",
  "p_somethingWrong": "Что-то пошло не так",
  "g_joinDiscord": "Присоединяйся к TDR Discord серверу, чтобы обсудить улучшения Вашего шаблона или почему Ваш шаблон был отклонён.",
  "m_timeToBeat": "Время соперника",
  "g_rule0": "Ваш шаблон должен иметь контекст для авто, который имеет смысл.  Например, одинаковая метка, одинаковая страна, одинаковые шины, одинаковый производитель, одинаковый класс... Или же контекст, который делают Hutch как требования для событий/испытаний.",
  "g_rule1": "Вы должны добавить все авто, доступны для контекста или хотя бы попытаться добавить их все. Добавляйте не только те авто, которые есть у Вас.",
  "g_rule2": "Шаблон должен сравнивать авто с похожими характеристиками. Нет смысла сравнивать заднеприводное авто на динамике с полноприводными всесезонными авто на трассах с сухим асфальтом.",
  "g_rule3": "Смешивайте трассы по асфальту с трассами по бездорожью только тогда, когда в этом есть смысл.",
  "g_rule4": "Не добавляйте все возможные трассы, доступные в игре. Приложение будет иметь проблемы с производительностью.",
  "g_rule5": "Старайтесь сортировать трассы по типу, например, группируя прямые трассы или трассы с одинаковым покрытием.",
  "g_rule6": "Старайтесь сортировать авто по RQ (в порядке убывания) или по производительности на выбранных трассах.",
  "g_rule7": "Шаблон не обязательно должен иметь заполненным каждый результат, но Вы можете заполнить всё, что можете перед отправкой.",
  "g_rule8": "Используйте наглядное название.",
  "g_rule9": "Проверьте, не существет ли уже шаблон с такой же целью, как у Вас.",
  "m_checkOut": "Взгляните на",
  "s_dry": "Сухо",
  "s_aspht": "Асф.",
  "s_wet": "Дождь",
  "s_gravel": "Гравй",
  "s_ice": "Лёд",
  "s_sand": "Песок",
  "s_snow": "Снег",
  "s_grass": "Трава",
  "s_dirt": "Грунт",
  "c_topSpeed": "Скор.",
  "c_handling": "Управление",
  "c_hand": "@:c_handling | @:c_handling",
  "c_class": "Класс | Классы",
  "c_year": "Год | Годы",
  "c_tyre": "Шина | Шины",
  "c_drive": "Привод | Приводы",
  "c_clearance": "Дорожный просвет | Дорожные просветы",
  "c_country": "Страна | Страны",
  "c_brand": "Производитель | Производители",
  "c_tag": "Метка | Метки",
  "c_stock": "Изначальный",
  "c_weight": "Вес",
  "c_fuel": "Топливо",
  "c_seats": "Количество мест",
  "c_enginePos": "Расположение двигателя",
  "c_bodyStyle": "Стиль кузова",
  "c_prizeCar": "Призовая тачка",
  "c_prize cars": "Призовые тачки",
  "c_non-prize cars": "Непризовые тачки",
  "c_mid": "Средний",
  "c_low": "Низкий",
  "c_high": "Высокий",
  "c_performance": "Динамика",
  "c_standard": "Стандартные",
  "c_all-surface": "Всесезонные",
  "c_off-road": "Внедорожные",
  "c_slick": "Гоночные",
  "c_saloon": "Седан",
  "c_roadster": "Родстер",
  "c_coupe": "Купе",
  "c_hatchback": "Хэтчбэк",
  "c_suv": "Внедорожник",
  "c_convertible": "Откр. верх",
  "c_estate": "Универсал",
  "c_pickup": "Пикап",
  "c_mpv": "Минивэн",
  "c_van": "Фургон",
  "c_dragster": "Драгстер",
  "c_all-rounder": "Всесторонний",
  "c_twister": "Для извилистых",
  "c_petrol": "Бензин",
  "c_diesel": "Дизель",
  "c_electric": "Электро",
  "c_hybrid": "Гибрид",
  "c_bioethanol": "Биоэтанол",
  "c_hydrogen": "Водород",
  "c_misc": "Разное",
  "c_frontEngine": "Переднее",
  "c_midEngine": "Среднее",
  "c_mixedEngine": "Смешанное",
  "c_rearEngine": "Заднее",
  "c_mid-rearEngine": "Смещенное назад",
  "t_drag100b": "0-100-0mph",
  "t_drag100": "0-100mph",
  "t_drag120": "0-120mph",
  "t_drag150b": "0-150-0mph",
  "t_drag150": "0-150mph",
  "t_drag170": "0-170mph",
  "t_drag200": "0-200mph",
  "t_drag60b": "0-60-0mph",
  "t_drag60": "0-60mph",
  "t_mile1": "Трасса, 1 миля",
  "t_mile1r": "Трасса, 1 миля (R)",
  "t_gForcer": "Тест на перегрузки (R)",
  "t_gForce": "Тест на перегрузки",
  "t_slalom": "Тест для слалома",
  "t_slalomr": "Тест для слалома (R)",
  "t_mile2": "Трасса, 1/2 мили",
  "t_mile4": "Трасса, 1/4 мили",
  "t_mile4r": "Трасса, 1/4 мили (R)",
  "t_drag100150": "100-150mph (R)",
  "t_drag30130": "30-130mph (R)",
  "t_drag50150": "50-150mph (R)",
  "t_drag75125": "75-125mph (R)",
  "t_airplaneHangars": "Авиационные ангары",
  "t_airplaneSlalom": "Авиационный слалом",
  "t_hairpin": "Серпантин",
  "t_mnCity": "Монако: Городские улицы",
  "t_northloop": "Northloop",
  "t_northloop1": "Northloop 1",
  "t_northloop2": "Northloop 2",
  "t_northloop3": "Northloop 3",
  "t_northloop4": "Northloop 4",
  "t_northloop5": "Northloop 5",
  "t_canyonTour": "Каньон: Экспедиция",
  "t_tRoad": "Извилистая дорога",
  "t_carPark": "Парковка",
  "t_csMed": "Улицы: Ср.",
  "t_csSmall": "Улицы: Мал.",
  "t_indoorKart": "Закрытый картинг",
  "t_fast": "Быстрая трасса",
  "t_fastr": "Быстрая трасса (R)",
  "t_testBowl": "Тестовый круг",
  "t_testBowlr": "Тестовый круг (R)",
  "t_kart": "Трасса для картинга",
  "t_tCircuit": "Извилистая трасса",
  "t_tCircuitr": "Извилистая трасса (R)",
  "t_figureEight": "\"Восьмёрка\"",
  "t_forest": "Лесная дорога",
  "t_mtHairpin": "Горы: Серпантин",
  "t_frozenLake": "Замерзшее озеро",
  "t_hClimb": "Подъём на холм",
  "t_draglshape": "L-образная трасса",
  "t_mtTwisty": "Горы: Извилистая дорога",
  "t_mnHairpin": "Монако: Серпантин",
  "t_tokyoOverpass": "Токио: Мостик",
  "t_mnGforce": "Монако: Тест на перегрузки",
  "t_mnCityNarrow": "Монако: Узкие улицы",
  "t_mnCityLong": "Монако: Длинные городские улицы",
  "t_moto": "Трасса для мотокросса",
  "t_oceanSlalom": "Пляжный слалом у океана",
  "t_rallySmall": "Ралли-кросс: Мал.",
  "t_rallyMed": "Ралли-кросс: Ср.",
  "t_runwayDrag": "Взлётка",
  "t_tokyoLoop": "Токио: Петля",
  "t_tokyoOffRamp": "Токио: Съезд",
  "t_waterDrag": "Трасса: Набережная",
  "t_hClimbr": "Подъём на холм (R)",
  "t_mtHill": "Горы: Подъём на холм",
  "t_mtSlalom": "Горы: Слалом",
  "t_oceanCity": "Городские улицы у океана",
  "t_oceanHighway": "Магистраль у океана",
  "t_oceanLongDrag": "Длинная трасса у океана",
  "t_oceanParking": "Парковка у океана",
  "t_oceanShortDrag": "Короткая трасса у океана",
  "t_runwayDragSlalom": "Взлётка и слалом",
  "t_tokyoGforce": "Токио: Тест на перегрузки",
  "t_tokyoBridge": "Токио: Мост",
  "t_forestSlalom": "Лесной слалом",
  "t_tokyoDrag": "Токио: Трасса",
  "t_serviceRoads": "Служебные дороги",
  "t_mtIncline": "Горы: Дорога с уклоном",
  "t_mtTour": "Горы: Экспедиция",
  "t_forestRiver": "Лесная переправа",
  "t_speedbump12km": "Гонка с препятствиями: 1/2 км",
  "t_speedbump1km": "Гонка с препятствиями: 1 км",
  "t_runwayLoop": "Петля на взлётке",
  "t_cross": "Напрямик",
  "t_fieldSlalom": "Полевой Слалом",
  "t_smallRoad": "Проселочная Дорога",
  "m_by": "by",
  "t_butte": "Крутой холм",
  "t_canyonLookout": "Каньон: Обзор",
  "t_canyonDtRoad": "Каньон: ",
  "t_canyonButte": "Каньон: Крутой холм",
  "t_lookout": "Обзор",
  "t_dtRoad": "Грунтовая дорога",
  "p_cgNotThisTime1": "Это испытание недоступно здесь",
  "p_cgNotThisTime2": "Мы решили не размещать это испытание на TDR. Давайте играть по старинке, просто ради развлечения. До TDR мы все это делали, можем сделать снова. Может быть, это слишком сложно или рискованно, но только это испытание. Остальные испытания работают нормально.",
  "p_cgNotThisTime3": "Почему? Мы потеряли некоторые социальные способы поиска помощи и решений. На этот раз мы хотим, чтобы игроки помогали друг другу, а не TDR предлагал все решения. Помощь друг другу — это основа каждого сообщества. Discord, Facebook, Reddit, Youtube... Их много. Вы не состоите ни в одном сообществе? Есть вкладка со многими из них, найдите то, которое вам нравится:",
  "p_cgNotThisTime4": "Просто для ясности: это не платный доступ. Даже покровители или модераторы не могут видеть раунды.",
  "p_cgNotThisTime5": "Надеюсь, вы понимаете. Приятного просмотра!",
  "m_vNewPhotos": "Новые фото {version}",
  "m_logout": "Выйти",
  "m_kingForceVertical": "Изменить отображение на вертикальное",
  "m_showBigCards": "Показать большие карты",
  "m_chartHideOutOfFilter": "Скрыть автомобили из фильтра",
  "m_meta": "Мета",
  "m_race": "Гонка",
  "m_useTrackList": "Использовать список треков",
  "m_showPointsCgForce": "показать точки",
  "m_statsView": "Просмотр статистики",
  "m_multiTags": "Несколько тегов",
  "m_hidden": "Скрытые",
  "m_enablePicks": "Включить выбор",
  "m_clearPicks": "Очистить выбор",
  "m_findRqSavers": "Найти RQ savers",
  "m_homePointsToggle": "Показать переключатель, чтобы показать баллы",
  "m_zoom": "Увеличить",
  "m_review": "Отзыв| Отзывы",
  "m_addReview": "Добавить отзыв",
  "m_clubs": "Клубы",
  "p_emptyRoundVoteForUser": "С этой отправкой все в порядке?",
  "p_beforeLeave": "Вы уверены, что хотите выйти?",
  "p_eventsKingLogin": "Войдите, чтобы увидеть лучшие авто каждого класса",
  "p_eventHiddenForPatreons": "Это событие доступно для обзора для патреонов, как минимум 3 уровня",
  "p_linkDiscordExplanationCommu": "Чтобы представить свое личное сообщество, вам необходимо",
  "p_linkDiscordExplanationP1": "Чтобы привязать свой аккаунт, вам необходимо",
  "p_linkDiscordCodeSent": "Код был отправлен в личное сообщение Discord",
  "c_tcs": "TCS",
  "c_abs": "ABS",
  "c_template": "Шаблон",
  "c_name": "Название",
  "m_resetSavedHand": null,
  "m_showCustomTunes": null,
  "m_modShowSuspectTimes": null,
  "m_newByCopy": null,
  "m_eventShowOnlyPicks": null,
  "m_eventForcePicks": null,
  "m_multi": null,
  "m_showContributors": null,
  "m_tracksetName": null,
  "m_tracksets": null,
  "m_reqs": null,
  "m_reqsName": null,
  "m_daySelector": null,
  "m_current": null,
  "m_saveDay": null,
  "m_createNewTrackset": null,
  "m_createNewReqs": null,
  "m_saveTrackset": null,
  "m_saveReqs": null,
  "m_saveOriginalOrder": null,
  "m_showOriginalOrder": null,
  "m_filterPage": null,
  "m_notCurrent": null,
  "m_admin": null,
  "m_carFinder": null,
  "m_newPhotos": null,
  "m_testPoints": null,
  "m_downTimes": null,
  "m_translationTool": null,
  "m_newCar": null,
  "m_relativeBest": null,
  "m_2xmultiplier": null,
  "m_cheatSheet": null,
  "m_simulateUntilGetAll": null,
  "m_simulateUntilGetOne": null,
  "m_simulate": null,
  "m_simulating": null,
  "m_impossibleRemoved": null,
  "m_count": null,
  "m_quantityOfCars": null,
  "m_success": null,
  "m_failed": null,
  "m_status": null,
  "m_cost": null,
  "m_stop": null,
  "m_probabilityPerOpen": null,
  "m_cumulativeProbability": null,
  "m_superLucky": null,
  "m_veryLucky": null,
  "m_goodLuck": null,
  "m_littleLucky": null,
  "m_badLuck": null,
  "m_worstLuck": null,
  "m_zeroLuck": null,
  "m_impossible": null,
  "m_customizePack": null,
  "m_impossiblePack": null,
  "m_impossibleCondition": null,
  "m_permanents": null,
  "m_linkDiscord": null,
  "m_sixDigitCode": null,
  "m_discordUserId": null,
  "m_discordLinkFinish": null,
  "m_submitYourCommunity": null,
  "m_wantToJoin": null,
  "m_private": null,
  "m_public": null,
  "m_official": null,
  "m_platform": null,
  "m_selectPlatform": null,
  "m_selectCountry": null,
  "m_userCount": null,
  "m_description": null,
  "m_optional": null,
  "m_informationForInvite": null,
  "m_link": null,
  "m_unlinkDiscord": null,
  "m_inviter": null,
  "m_requestSent": null,
  "m_edit": null,
  "m_saveToFile": null,
  "m_loadFile": null,
  "m_replace": null,
  "m_eventPack": null,
  "m_accelCurveMaker": null,
  "m_speed": null,
  "m_clearBoard": null,
  "m_longTerm": null,
  "m_selectType": null,
  "m_rewardedCar": null,
  "m_deleteEvent": null,
  "m_paste": null,
  "m_date": null,
  "m_dateEnd": null,
  "m_images": null,
  "m_links": null,
  "m_exportToTimeline": null,
  "m_spec": null,
  "m_text": null,
  "m_noGoal": null,
  "m_specificCar": null,
  "m_specificAttr": null,
  "m_nextPage": null,
  "m_previousPage": null,
  "m_videoShowcase": null,
  "m_video": null,
  "m_addVideo": null,
  "m_removeVideo": null,
  "m_toggleVideo": null,
  "m_downloadImage": null,
  "m_removeCar": null,
  "m_needHelp": null,
  "m_instructions": null,
  "m_myGarage": null,
  "m_topDrivesWrapped": null,
  "m_wrapped": null,
  "m_carCount": null,
  "m_fullyUpgraded": null,
  "m_prizes": null,
  "m_nonPrizes": null,
  "m_nonPrizesMissing": null,
  "m_count332": null,
  "m_count323": null,
  "m_count233": null,
  "m_mostWins": null,
  "m_mostLoses": null,
  "m_mostUsed": null,
  "m_highWinRate": null,
  "m_highLoseRate": null,
  "m_oldestNoUse": null,
  "m_mostUseDay": null,
  "m_lessUseDay": null,
  "m_oldest": null,
  "m_mostWinDay": null,
  "m_mostLoseDay": null,
  "m_newGroup": null,
  "m_newTimeline": null,
  "m_uploadMyGarage": null,
  "m_updateGarage": null,
  "p_eventsKingDescription": null,
  "p_linkDiscordExplanationP2": null,
  "p_informationForInvite": null,
  "p_inviteYourDiscord": null,
  "p_privateDescript": null,
  "p_publicDescript": null,
  "p_userSentCommunityForAnalyse": null,
  "p_communityToBeInvited": null,
  "p_privateGarageDesc": null,
  "p_publicGarageDesc": null,
  "c_brakeClass": null,
  "c_brake": null,
  "c_speedster": null,
  "c_tune": null,
  "t_townSlalom": null,
  "t_townSprint": null,
  "t_townTour": null,
  "t_frozenRivSla": null,
  "t_riverSprint": null,
  "t_laguna": null,
  "t_lumberTwisty": null,
  "t_desertHill": null,
  "t_desertSmall": null,
  "t_desertBig": null,
  "t_desertRallyDirt": null,
  "t_dockAltCirc": null,
  "t_dockCirc": null,
  "t_dockCity": null,
  "t_dockGforceDrag": null,
  "t_dockKart": null,
  "t_dockUdrag": null,
  "t_lumberForest": null,
  "t_lumberRiver": null,
  "t_drag62": null,
  "t_drag124": null,
  "t_dealsGap1": null,
  "t_dealsGap2": null,
  "t_dealsGap3": null,
  "t_dealsGap4": null,
  "t_dealsGap5": null,
  "t_dealsGap": null,
  "t_dealsGapBack": null,
  "t_nwCircuit": null,
  "t_nwDrag": null,
  "t_nwGforce": null,
  "t_nwLoop": null,
  "t_nwCathedral": null,
  "t_nwTour": null,
  "t_nwSlalom": null,
  "t_miCircuit": null,
  "t_miStreets1": null,
  "t_miStreets2": null,
  "t_miUdrag": null,
  "t_miCause": null,
  "t_miCauseDrag": null,
  "t_miBridge": null,
  "t_miFigure": null,
  "t_miSlalom": null,
  "t_miGforce": null,
  "t_miBeachDrag": null
}